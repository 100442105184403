define("discourse/plugins/waze-wiki/discourse/templates/connectors/topic-above-post-stream/waze-wiki-badge", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @outletArgs.model.postStream.posts.0.wiki}}
    <WazeWikiBadge @class="wiki-badge-topic-above-post-stream" />
  {{/if}}
  */
  {
    "id": "svo6kphL",
    "block": "[[[41,[30,1,[\"model\",\"postStream\",\"posts\",\"0\",\"wiki\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@class\"],[\"wiki-badge-topic-above-post-stream\"]],null],[1,\"\\n\"]],[]],null]],[\"@outletArgs\"],false,[\"if\",\"waze-wiki-badge\"]]",
    "moduleName": "discourse/plugins/waze-wiki/discourse/templates/connectors/topic-above-post-stream/waze-wiki-badge.hbs",
    "isStrictMode": false
  });
});