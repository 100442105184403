define("discourse/plugins/waze-wiki/discourse/templates/connectors/search-results-topic-title-suffix/waze-wiki-badge", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @outletArgs.topic.first_post_wiki}}
    <WazeWikiBadge @class="wiki-badge-search-results-topic-title-suffix" />
  {{/if}}
  */
  {
    "id": "u7X4k6aM",
    "block": "[[[41,[30,1,[\"topic\",\"first_post_wiki\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@class\"],[\"wiki-badge-search-results-topic-title-suffix\"]],null],[1,\"\\n\"]],[]],null]],[\"@outletArgs\"],false,[\"if\",\"waze-wiki-badge\"]]",
    "moduleName": "discourse/plugins/waze-wiki/discourse/templates/connectors/search-results-topic-title-suffix/waze-wiki-badge.hbs",
    "isStrictMode": false
  });
});