define("discourse/plugins/waze-wiki/discourse/components/waze-wiki-badge", ["exports", "@ember/component", "@glimmer/component", "I18n", "@ember/template-factory"], function (_exports, _component, _component2, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="waze-wiki-badge {{@class}}">
    <div class="waze-wiki-badge-content">
      {{dIcon "w-doc"}}
      <wz-caption>{{this.badgeText}}</wz-caption>
    </div>
  </div>
  */
  {
    "id": "t7wKEOGM",
    "block": "[[[10,0],[15,0,[29,[\"waze-wiki-badge \",[30,1]]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"waze-wiki-badge-content\"],[12],[1,\"\\n    \"],[1,[28,[35,1],[\"w-doc\"],null]],[1,\"\\n    \"],[10,\"wz-caption\"],[12],[1,[30,0,[\"badgeText\"]]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@class\"],false,[\"div\",\"dIcon\",\"wz-caption\"]]",
    "moduleName": "discourse/plugins/waze-wiki/discourse/components/waze-wiki-badge.hbs",
    "isStrictMode": false
  });
  class WazeWikiBadge extends _component2.default {
    get badgeText() {
      return _I18n.default.t("waze.wiki.wiki_badge_text");
    }
  }
  _exports.default = WazeWikiBadge;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, WazeWikiBadge);
});